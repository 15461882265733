import React from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
    sx?: Object,
};

const Logo: React.FC<Props> = ({ sx }) => {
    return (
        <Stack flexDirection="row" mt={0.4} mb={3} display="flex" alignContent={"center"} alignItems={"center"} justifyItems={"center"} justifyContent={'center'}>
           <img alt="logo" src="/static/illustrations/logo.png" style={{ height: 200, width:200, marginBottom: -80, marginTop: -90 }} />
        </Stack>
    )
}

export default Logo;