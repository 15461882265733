import { InputForm } from "../types";

const VALUE = 97.90;

export namespace CreditCardFormBuild {
    export const form: InputForm[] = [
        {
            label: 'Número do Cartão *',
            id: 'cardNumber',
            required: true,
            type: 'text',
            mask: '9999 9999 9999 9999',
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        {
            label: 'Nome Impresso no Cartão *',
            id: 'holderName',
            required: true,
            type: 'text',
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        {
            label: 'Validade *',
            id: 'expirationDate',
            mask: '99/9999',
            required: true,
            type: 'text',
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        {
            label: 'CVV *',
            id: 'securityCode',
            type: 'text',
            mask: '999',
            required: true,
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        {
            label: 'Parcelas (até 12 vezes sem juros) *',
            id: 'installmentQuantity',
            type: 'select',
            required: true,
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 12,
            },
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((v) => ({
                label: `${v}x de R$ ${(VALUE / v).toFixed(2)} (SEM JUROS)`,
                value: v,
            }))
        },
    ]
}