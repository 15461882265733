import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Divider,
  Stack,
} from '@mui/material';
import { Copy, CheckCircle2 } from 'lucide-react';

interface Props {
    name: string
    paymentData: any
}

export function PayPix({ name, paymentData }: Props) {
  const [timeLeft, setTimeLeft] = useState<string>('33:31');
  const [copied, setCopied] = useState(false);

  const mockPixCode = paymentData?.payload;

  useEffect(() => {
    const timer = setInterval(() => {
      const [minutes, seconds] = timeLeft.split(':').map(Number);
      let newSeconds = seconds - 1;
      let newMinutes = minutes;

      if (newSeconds < 0) {
        newSeconds = 59;
        newMinutes -= 1;
      }

      if (newMinutes < 0) {
        clearInterval(timer);
        return;
      }

      setTimeLeft(`${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(mockPixCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Stack spacing={3}>
        <Paper elevation={0} sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Olá, {name}!
          </Typography>
          <Typography color="text.secondary">
            Recebemos o seu pedido.
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            Seu processo será finalizado após o pagamento.
          </Typography>
          <Typography>
            Segue abaixo a forma de pagamento selecionada.
          </Typography>
        </Paper>

        <Paper sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Box sx={{ textAlign: 'center' }}>
                <img 
                    src={`data:image/png;base64, ${paymentData?.encodedImage}`} 
                    alt="QR Code"
                    style={{ width: 350, height: 350, margin: '0 auto' }}
                />
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                O QRCode irá expirar em:
              </Typography>
              <Typography variant="h4" color="primary" fontWeight="bold">
                {timeLeft}
              </Typography>
            </Box>

            <Divider />

            <Box>
              <Typography variant="subtitle2" gutterBottom>
                PIX COPIA&COLA
              </Typography>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  bgcolor: 'grey.50',
                  wordBreak: 'break-all',
                  fontSize: '0.875rem',
                }}
              >
                {mockPixCode}
              </Paper>
              <Button
                variant="contained"
                startIcon={copied ? <CheckCircle2 size={20} /> : <Copy size={20} />}
                onClick={handleCopyClick}
                fullWidth
                sx={{ mt: 2 }}
              >
                {copied ? 'Copiado!' : 'Copiar Pix Copia&Cola'}
              </Button>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <Typography color="text.secondary" variant="body2">
                Após finalizar o pagamento, você pode continuar nessa página para aguardar a confirmação do pagamento ou fechar. Você receberá uma notificação assim que o pagamento for confirmado.
              </Typography>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress size={24} sx={{ mr: 1 }} />
              <Typography color="primary" display="inline">
                AGUARDANDO O PAGAMENTO...
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}